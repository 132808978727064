.transition-all-short {
    transition: all 0.2s ease !important;
}
.transition-all-long {
    transition: all 0.4s ease !important;
}

.topbar {
    position: absolute;
    top:0;
    left:0;
    background-color: rgb(37,137,0);
    width:100%;
    color:white;
}