body{
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height:100vh;
  width:100vw;
}


::-webkit-scrollbar {
  width: 10px !important;
  height:10px !important;  
}


  /* color of the tracking area */
::-webkit-scrollbar-track {
  background:rgb(37,137,0) !important;      
  border-radius: 4px;            
}

::-webkit-scrollbar-thumb {
  background-color:rgb(25, 95, 0) !important;      
  border-radius: 20px !important;     
  border: 1px solid grey !important;      
}